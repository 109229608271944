<template>
    <div>
        <div>
          <v-card v-for="receiver in receivers" :key="receiver.id" class="mt-2 card">
            <v-card-subtitle class="titleFont--text font-weight-medium pa-0 mr-4">
            <div class="d-flex align-center">
            <v-radio-group @change="receiveraddress()" v-model="radioGroup">
              <v-radio :value="receiver.id" color="primary"></v-radio>
              </v-radio-group>
              <span v-text="receiver.title"></span>
              <v-spacer></v-spacer>
            <optionMenu :receiver = 'receiver' color="muted" size="20"/>
              </div>
            </v-card-subtitle>
             <v-row class="mr-2">
              <v-col cols="1" class="py-2">
                <v-icon>mdi-account</v-icon>
              </v-col>
              <v-col class="py-2">
                <span class="medium-font mr-1" v-text="receiver.receiver"></span>
              </v-col>
            </v-row>

            <v-row class="mr-2">
              <v-col cols="1" class="py-2">
                <v-icon>mdi-sign-real-estate</v-icon>
              </v-col>
              <v-col class="py-2">
                <span class="medium-font mr-1"><span v-text= "receiver.province"></span>/ <span v-text= "receiver.city"></span>/ <span v-text= "receiver.address"></span></span>
              </v-col>
            </v-row>

            <v-row v-if="receiver.postal_code" class="mr-2">
              <v-col cols="1" class="py-2">
                <v-icon>mdi-email</v-icon>
              </v-col>
              <v-col class="py-2">
                <span class="medium-font mr-1" v-text="receiver.postal_code"></span>
              </v-col>
            </v-row>

            <v-row class="mr-2">
              <v-col cols="1" class="py-2">
                <v-icon>mdi-cellphone-iphone</v-icon>
              </v-col>
              <v-col class="py-2">
                <span class="medium-font mr-1" v-text="receiver.mobile"></span>
              </v-col>
            </v-row>

            <v-row v-if="receiver.phone" class="mr-2">
              <v-col cols="1" class="py-2">
                <v-icon>mdi-phone</v-icon>
              </v-col>
              <v-col class="py-2">
                <span class="medium-font mr-1" v-text="receiver.phone"></span>
              </v-col>
            </v-row>

            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import Store from '@/store/index'
import optionMenu from '../lists/dotsMenu'
import router from '@/router'
export default {
  components: {
    optionMenu
  },
  props: ['address'],
  computed: {
    ...mapState({
      receivers: state => state.address.UserAddress,
      addressBasket: state => state.basket.addressBasket,
    })
  },
  watch: {
    receivers: {
      deep: true,
      handler: function () {
        if (this.receivers.length == 0) {
          router.push('/add_reciever')
          return
        }
        if (this.addressBasket) {
          this.radioGroup = this.addressBasket
          Store.dispatch('addressBasket', this.radioGroup)
          return
        }
        this.radioGroup = this.receivers[0].id
        Store.dispatch('addressBasket', this.radioGroup)
      }
    }
  },
  data: () => ({
    radioGroup: 0,
  }),
  methods: {
    receiveraddress() {
      Store.dispatch('addressBasket', this.radioGroup)
    }
  }
}
</script>
